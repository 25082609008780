import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { HeadlineAlign, HeadlineType } from '../../types/types';

@Component({
  selector: 'headline',
  standalone: true,
  imports: [],
  templateUrl: './headline.component.html',
  styleUrl: './headline.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HeadlineComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public label!: string;

  @Input({ required: false })
  public type: HeadlineType = 1;

  @Input({ required: false })
  public align: HeadlineAlign = 'center';

  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {
    this._toggleType(this.type);

    this._toggleAlign(this.align);
  }

  public ngOnChanges({ type, align }: SimpleChanges) {
    if (type && !type.firstChange) {
      this._toggleType(type.currentValue, type.previousValue);
    }
    if (align && !align.firstChange) {
      this._toggleAlign(align.currentValue, align.previousValue);
    }
  }

  private _toggleType(type: HeadlineType, oldType?: HeadlineType) {
    const element: HTMLElement = this._self.nativeElement as HTMLElement;

    if (oldType) {
      element.classList.remove(`headline-${oldType}`);
    }

    element.classList.add(`headline-${type}`);
  }

  private _toggleAlign(type: HeadlineAlign, oldType?: HeadlineAlign) {
    const element: HTMLElement = this._self.nativeElement as HTMLElement;

    if (oldType) {
      element.classList.remove(`headline-align-${oldType}`);
    }

    element.classList.add(`headline-align-${type}`);
  }
}

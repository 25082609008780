<ng-container>
  <div
    #user_content
    [title]="label"
    [class]="{
      'headline': true,
      'hidden': user_content.innerText.length < 1,
    }"
  ><ng-content /></div>

  @if (user_content.innerText.length < 1) {
    <div
      [title]="label"
      class="headline"
    >{{ label }}</div>
  }
</ng-container>
